.btn {

     &.btn-primary,&.btn-info {
          background: $teal;
          background: linear-gradient(to bottom right, lighten($teal, 25%), lighten($teal, 10%));
          border-color: lighten($teal, 15%);
     }

}

.dropdown-toggle {
     background-color: $gray-200 !important;
}

@media(min-width: 600px) {
     .btn-sm-inline {
          display: inline-block;
          width: auto;
     }
}