#export-content {


    p, h1, h2, h3, h4, h5, h6, td, span, b, li, ul {
        color: black !important;
    }

    .table {

        border-left: 1px solid black !important;
        border-right: 1px solid black !important;


        td, th {
            border-top: 1px solid black !important;
        }

        th {
            border-bottom: 1px solid black !important;
            color: black !important;
            font-size: .9rem;
        }

        tr:last-child {
            border-bottom: 1px solid black !important;

        }

    }

    .card-header {
        border-left: 1px solid black !important;
        border-right: 1px solid black !important;
        border-top: 1px solid black !important;
    }

}
