body.analytics-sidebar-small {
    
    @media(min-width: 768px) {
        .sidebar {
            width: 50px;
            overflow: hidden;
    
        }
    
        .content {
            margin-left: 50px;
        }
    }

}

.analytics-layout, .analytics-sidebar {

    $sidebarWidth: 250px;
    $headerHeight: 53px;

    .content {
        margin-top: $headerHeight;
    }

    @media(max-width: 768px) {
        .content {
            margin-top: 0;
        }
    }

    .analytics-header {

        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 1000;

        .analytics-header-wrapper {
            padding: $spacer * 0.75 0 ;
        }

        display: flex;

        ul {

            margin-bottom: 0;
            padding-left: 0;

            li {

                a {
                    color: white;
                    padding: 6px 12px;
                    display: inline-block;

                }

                &.logo {
                    img {
                        height: 20px;
                        width: auto;
                    }
                }

            }

        }

    }

    .sidebar {

        // transition: all .4s;
        position: fixed;
        left: 0;
        top: $headerHeight;
        bottom: 0;
        width: $sidebarWidth;
        background-color: $white;
        z-index: 1020;
        overflow: auto;

        padding-left: 0;
        padding-right: 0;

        .row {
            margin-left: 0;
            margin-right: 0;
        }


        .sidebar-small {

            min-height: calc(100vh - 53px);


            background: $gray-200;
            border-right: solid 1px $white;


            padding-left: 0;
            padding-right: 0;
            text-align: center;

            a {
                padding: $spacer * .75 $spacer * 1.5;
                border-bottom: solid 1px $gray-400;

                &.active {
                    i {
                        color: $red;
                    }
                }

                &:hover {
                    background: $gray-400;
                }

            }

            ul {

                li {
                    
                    display: block;
                    


                    i {
                        width: auto;
                    }
                }

            }

        }

        .sidebar-large {
            padding-left: 0;
            padding-right: 0;
        }

        .main-system {
            background-color: $gray-300;
            &:hover {
                background-color: $gray-400;
            }
        }

        .dropdown-container {

            overflow: hidden;

            .dropdown-list {
                display: none;


                &.open {
                    display: block;
                    height: 100%;
                }

            }

        }

        ul {
            padding-left: 0;
            list-style: none;

            .logo {
                padding: $spacer * 1.5 $spacer * 4;
                border-bottom: solid 1px $gray-200;
                background: $primary;

            }

            li {

                border-bottom: solid 1px $gray-200;

                &.on-page {
                    a {
                        color: $orange;
                        font-weight: bold;
                        border-left: solid 1px $orange;
                        border-right: solid 1px $orange;
                    }
                }

                a, .false-a {
                    padding: $spacer * 0.75 $spacer * 1.5;
                    display: block;
                    color: $body-color;
                    font-size: 1rem;
                    cursor: pointer;
                    transition: all 0.3s;

                    &.open {
                        background-color: $orange;
                        color: $white !important;

                        i {
                            color: $white !important;
                        }

                        &:hover {
                            background: darken($orange, 2%);
                        }
                    }

                    i {
                        display: inline-block;
                        width: 30px;
                        color: $gray-700;
                    }
                    &:hover {
                        background-color: $gray-200;
                    }

                    &.active {
                        background-color: $gray-300;
                    }

                }

                ul {

                    background: $gray-100;

                    li {

                        border-bottom: solid 1px $gray-300;
                        a {
                            padding-left: 47px;
                        }
                        .inner-content {
                            padding-left: 0px;
                        }
                    }

                }

            }
        }

    }

    .content {
        margin-left: $sidebarWidth;

        .graph {

            position: relative;

            &.fullscreen {
                position: fixed;
                top: 0;
                bottom: 0;
                right: 0;
                left: 0;
                z-index: 99999;
                background: white;
                display: flex;

                .card {
                    align-self: center;
                    margin: auto;
                }
            }

            .no-data {

                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                z-index: 1;
                background: rgba(0,0,0,.7);
                text-align: center;
                font-size: 2rem;;
                color: white;
                display: flex;


                .no-data-content {
                    width: 100%;
                    align-self: center;
                }

            }

            .toggler {
                background: $gray-200;
                padding: 3px 9px;
                border-radius: 3px;
                margin-left: 10px;
                font-size: 1rem;;
                cursor: pointer;
            }

            .card-body {
                transition: all .3s;
                // height: 300px;
                height: inherit;
            }

            .hidden {

                height: 0;
                overflow: hidden;
                padding: 0;


            }

        }

    }

    .page_workflows_view .archk-flow .sidebar {
        top: $headerHeight;
        left: $sidebarWidth;
    }
    .page_workflows_view .archk-flow .flow {
        margin-top: $headerHeight;
    }

    .mobile-header {
        display: none;
    }

    .blackout {
        display: none;
    }

    @media(max-width: 768px) {

        .sidebar {
            position: fixed;
            left: -300px;
            top: 0;
            width: 300px;

            overflow: auto;
            transition: all .3s;
            border-right: solid 1px $gray-200;

            &.show {
                left: 0;
            }
        }

        .content {
            margin-left: 0;
        }

        .mobile-header {
            display: block;
            img {
                max-width: 150px;
            }
        }

        .blackout {
            display: block;
            position: fixed;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            background: rgba(0,0,0,.5);
            z-index: 2;
        }

    }


    .query-container {

        .form-control {
            height: auto;
            font-size: 12px;
        }

        .react-datepicker__input-container input {
            font-size: 12px;
        }

        .base-selector {
            border-right: solid 1px $gray-300;
        }

        .card-body {
            padding-top: 0;
            padding-bottom: 0;

            .column {
                padding-top: $spacer * 1.5;
                padding-bottom: $spacer * 1.5;
            }

        }

        .filter-name {
            background: $gray-200;
        }

        .filter-container {

            background: $gray-300;
            margin-bottom: 0;
            padding: $spacer * 1.5 $spacer * 1.5;

            li {
                cursor: pointer;
                list-style: none;
                display: inline-block;
                color: $white;
                padding: 3px 12px;
                margin-right: 6px;
                border-radius: 6px;
                transition: all 0.2s;
                top: 0;

                &.active {
                    box-shadow: 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.3);
                    position: relative;
                    top: 3px;
                }
                &:nth-child(1) {
                    background: $white;
                    color: inherit;
                    margin-right: 30px;

                }
                &:nth-child(2) { background: $green; }
                &:nth-child(3) { background: $red; }
                &:nth-child(4) { background: $tealOriginal; }
                &:nth-child(5) { background: $indigo; }
                &:nth-child(6) { background: $cyan; }
                &:nth-child(7) { background: $yellow; }

            }

        }

    }

    .percent-breakdown {

        .legend {

            text-align: center;
            margin-bottom: 10px;

            .item {
                position: relative;
                display: inline-block;
                padding-right: 40px;

                &.day-15 {
                    .key {
                        background: $yellow;
                    }
                }

                &.day-30 {
                    .key {
                        background: lighten($orange, 5%);
                    }
                }


                &.day-60 {
                    .key {
                        background:$orange
                    }
                }


                &.day-60-plus {
                    .key {
                        background:  $red
                    }
                }

            }

            .key {
                width: 10px;
                height: 10px;
                position: absolute;
                left: -15px;
                top: 6px;
                border-radius: 100%
            }

        }

        .descriptor {
            display: inline-block;
            text-align: center;
            color: white;

            &.day-15 {
                background: $yellow;
            }

            &.day-30 {
                background: lighten($orange, 5%);
            }


            &.day-60 {
                background:$orange
            }


            &.day-60-plus {
                background:  $red
            }

        }
    }

    .card {
        margin-bottom: $spacer;
    }

    // .form-group {
    //     margin-bottom: 0rem;
    // }

    // .form-control-label {
    //     font-size: 12px;
    //     margin-bottom: 0px;
    // }

    // .form-control {

    //     height: 30px;
    //     min-height: 0px;
    //     font-size: 12px;
    //     padding-left: .6rem;
    //     padding-right: .6rem;
    //     padding-top: .1rem;
    //     padding-bottom: .1rem;
    // }

    // textarea.form-control {
    //     height: 60px;
    //     min-height: 60px;
    // }

}




