.archk-admin-alerts-feed {

    .archk-admin-alerts-feed-wrapper {
        border: solid 1px $gray-400;

        &.archk-admin-alerts-feed-wrapper-sales {
            .archk-admin-alerts-feed-title {
                border-top-left-radius: $border-radius;
                border-top-right-radius: $border-radius;
                background: $red;
                color: $secondary;
                .text-dark {
                    color: $white !important;
                }
            }
        }
        &.archk-admin-alerts-feed-wrapper-system {
            .archk-admin-alerts-feed-title {
                border-top-left-radius: $border-radius;
                border-top-right-radius: $border-radius;
                background: $tealOriginal;
                color: $secondary;
                .text-dark {
                    color: $white !important;
                }
            }
        }
        &.archk-admin-alerts-feed-wrapper-call-center {
            .archk-admin-alerts-feed-title {
                border-top-left-radius: $border-radius;
                border-top-right-radius: $border-radius;
                background: $orange;
                color: $secondary;
                .text-dark {
                    color: $white !important;
                }
            }
        }
        &.archk-admin-alerts-feed-wrapper-billing {
            .archk-admin-alerts-feed-title {
                border-top-left-radius: $border-radius;
                border-top-right-radius: $border-radius;
                background: $success;
                color: $secondary;
                .text-dark {
                    color: $white !important;
                }
            }
        }
    }


    a {
        text-decoration: underline;
        text-transform: capitalize;
        color: $tealOriginal;
    }

    .archk-admin-alerts-feed-title {
        font-weight: bold;
        text-transform: uppercase;
        position: relative;
        top: -3px;
    }

    .archk-admin-alerts-feed-text {
        line-height: 15px;
    }

    .archk-admin-alerts-feed-date {
        float: right;
        position: relative;
        top: 3px;
    }

} 