.archk-steps-to-prep-blackout {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 900;
    background: rgba(0,0,0,.5);
  
}

.archk-steps-to-prep-launch {
    position: fixed;
    bottom: 10px;
    right: 10px;
    background: white;
    z-index: 11000000000;
    padding: 8px 10px;
    border-radius: 100%;
    background: $orange;
    color: $white;
    cursor: pointer;

    &:hover {
        background: darken($orange, 10%);
    }
}

.archk-steps-to-prep {

    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    width: 850px;
    max-width: 100%;
    z-index: 1000;
    background: $white;

    .nav-pills .nav-link {

        padding-top: 3px;
        padding-bottom: 3px;

        &.active {
            color: $white;

            &.finished {
                i {
                    color: $white;
                }
                background: $green;
                color: $white !important;

              
    
            }
        }


        &.finished {
            i {
                color: $green;
            }

            background: lighten($green, 45);
            color: $green !important;

            outline: solid 2px $green;
        }
        
     
    }

    .card {
        box-shadow: none !important;
    }

    .archk-image-renderer {
        display: block;
        min-width: 260px;
        border-radius: $border-radius;
        border: solid 1px $gray-300;
        margin-top: 5px;
        padding: 5px;
        background-color: $gray-100;

        i {
            display: inline-block;
            width: 16px;
        }
    }

    .col-left {
        overflow: auto;
        max-height: 100%;
        display: flex;
        padding-right: 0;
        width: 360px;
        border-right: solid 1px $gray-300;

        .card {
            border: none;
            padding-left: 7px;
            padding-right: 7px;

            .card-title {
                font-size: 14px;
            }
        }

        .card {
            border-radius: 0 !important;
        }
    }

    .col-right {
        overflow: hidden;
        max-height: 100%;
        padding-left: 0;
        // padding-right: 0;
    }

    .archk-steps-to-prep-main {
        height: 100%;
        overflow: hidden;

        position: absolute;
        top: 77px;
        left: 0;
        right: 0;
        padding-bottom: 77px;

        #case_feed {
            // margin-bottom: 60px !important;
            max-height: 100%;

            #feed-wrapper {
                padding-bottom: 20px;
            }

            .navigation-wrapper {
                padding-top: 11px;
                padding-bottom: 11px;
            }
        }
    }

    .archk-steps-to-prep-header {
        padding: $spacer $spacer * 2;
    }
    .archk-steps-to-prep-container {
        padding: $spacer $spacer * 2;
        height: 100%;
        padding-bottom: 45px;
        overflow: auto;
    }
    
    .archk-steps-to-prep-header-sm {
        padding: 2px $spacer * 2;
    }

    .archk-steps-to-prep-spacer {
        padding-left: $spacer * 1;
        padding-right: $spacer * 2;
    }
    
    .archk-steps-to-prep-info {

        height: calc(100% - 47px);
        overflow: auto;
        padding-bottom: 0px;
        overflow: auto;
        padding-left: $spacer * .5;
        padding-right: $spacer * 2;

        .text-right {
            .btn-outline-success {
                display: none;
            }
        }
    }

    .archk-contact-questionnaires {
        h3, hr {
            display: none;
        }
    }

    .archk-steps-to-prep-docs  {
        height: calc(100% - 47px);
        overflow: auto;
        padding-bottom: 0px;

        .archk-contact-questionnaires {
            padding-bottom: 0;
        }

        .text-right {
            .btn-outline-success {
                display: none;
            }
        }

        .status {
            height: 22px;
            min-height: auto;
            line-height: 20px;
            text-align: center;
            position: relative;
            top: 2.5px;
            padding: 0;
            // padding-left: 20px;
        }
    }

    .archk-steps-to-prep-header {
        button {
            width: 100%;
        }
    }

    @media (max-width: 750px) {
        .col-left, .phone, .email {
            display: none;
        }
        .col-right {
            padding-left: $spacer !important;
        }

       

    }
    @media (max-width: 450px) {
        .archk-steps-to-prep-info {
            height: calc(100% - 200px);
        }
    }

    @media (max-width: 768px) {
        .archk-steps-to-prep-main {
            top: 102px;
        }
    }

    

}

