.archk-system-uploads {

    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    background: $white;
    z-index: 10000000000;
    max-height: 70px;
    overflow: auto;

    .container-fluid {
        padding-left: 0;
        padding-right: 0;
    }

    .table {

        tr {
            border-top: solid 1px $gray-300;
        }

        th, td {
            padding: 0;
        }

        td:first-child, th:first-child {
            padding-left: 5px;
        }

        td:last-child, th:last-child {
            padding-right: 5px;
            text-align: right;
        }

        th {
            padding: 3px 0;
        }

    }

}