.knowledge-base {

    p {
        margin-bottom: 0;
        font-size: $font-size-sm;
    }

    h4 { 
        margin-top: $spacer * 2
    }

    ol {
        margin-top: 1rem
    }
    
}

.modal.show .modal-full .modal-content {
    right: 0
}

.modal-full {
    max-width: 50vw;
    max-height: 100vh;
    padding: 0;
    margin: 0;
    right: 0;
    margin-left: auto;

    .modal-content {
        min-height: 100vh;
        height: 100vh;
        right: -5000px;
        transition: all .5s;
    }

}

@media(max-width: 600px) {
    .modal-full {
        max-width: 100vw;
    }
}