.archk-template-docs {

    .archk-template-docs-main {
        margin-left: 250px;
    }

    .archk-template-docs-sidebar {

        position: fixed;
        left: 0;
        bottom: 0;
        top: $fixed-header-height;
        overflow: auto;
        width: 250px;
        background: $white;
        border-right: solid 1px $gray-400;

        .section {
            padding: $spacer 1rem;
            h3 {
                margin-bottom: -5px;
            }
        }

        ul {

            padding-left: 0;
            margin-bottom: 0;

            &.hover-pop {

                li {
                    &:hover {
                        cursor: pointer;
                        background-color: $gray-300;
                    }
                }

            }

            li {
                list-style: none;
                padding: $spacer * .5 1rem;
                background-color: $gray-100;
                border-bottom: solid 1px $gray-400;

                

                &:first-child {
                    border-top: solid 1px $gray-200;
                }

                .icon-identifier {
                    i {
                        width: 20px;
                        height: 20px;
                        font-size: 9px;
                        background: $green;
                        padding: 4px;
                        color: white;
                        border-radius: 3px;
                        border: solid 2px white;
                        box-shadow: 2px 2px 2px 2px black;
                        
                        &.party-0 {
                            background-color: $primary;   
                        }margin-right: 16px;
                        &.party-1 {
                            background: $green;
                        }
                        &.party-2 {
                            background: $tealOriginal;
                        }
                        &.party-3 {
                            background: $red;
                        }
                        &.party-4 {
                            background: $purple;
                        }
        
                    }
                }

                .page-number {
                    float: right;
                    color: white;
                    width: 40px;
                    font-size: 12px;
                    border-radius: 20px;
                    background-color: $red;

                    &.party-0 {
                        background-color: $primary;   
                    }
                    &.party-1 {
                        background: $green;
                    }
                    &.party-2 {
                        background: $tealOriginal;
                    }
                    &.party-3 {
                        background: $red;
                    }
                    &.party-4 {
                        background: $purple;
                    }
                   
                    
                }

            }   

        }

    }

    .pdf-marker {

        position: absolute;
        z-index: 2;
        border: solid 2px darken($yellow, 5%);
        background: rgba(255, 214, 0, .3);;
        width: 100%;

        .marker-name {
            bottom: -3px;
            position: absolute;
            left: 0;
            width: 100%;
            font-weight: bold;
            font-size: 12px;
            white-space: pre;
        }

        &:hover {
            .toolbar {
                display: block;
            }
        }

        .toolbar {
            display: none;
            height: 30px;

            width: 150px;
            position: absolute;
            top: -33px;

            ul {

                li {
                    width: 30px;
                    height: 30px;
                    text-align: center;
                    padding-top: 4px;
                    cursor: pointer;
                    color: $gray-300;

                    &:hover {
                        background: $gray-600
                    }

                    &.active {
                        color: $green;
                    }

                }

            }

        }

        .identifier {

            i {

                position: absolute;
                bottom: -38px;
                left: calc(50% - 20px);
                width: 35px;
                height: 35px;
                background: $green;
                padding: 8px;
                color: white;
                border-radius: 3px;
                border: solid 2px white;
                box-shadow: 2px 2px 2px 2px black;

                &.party-0 {
                    background-color: $primary;   
                }
                &.party-1 {
                    background: $green;
                }
                &.party-2 {
                    background: $tealOriginal;
                }
                &.party-3 {
                    background: $red;
                }
                &.party-4 {
                    background: $purple;
                }

            }

        }

        .text-input {

            display: none;
            height: 30px;
            width: 150px;
            position: absolute;
            top: -78px;
        }


    }

}

// .archk-template-docs-send-modal {
//     align-items: flex-end;

//     .modal-content {
//         max-height: 80vh;
//     }
// }