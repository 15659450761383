.archk-system-dev-tickets {

    position: fixed;
    bottom: 15px;
    right: 15px;
    width: 300px;

    z-index: 999999999999999;
    background: $white;

    max-height: 400px;
    overflow: auto;
    border-radius: 6px;

    .close {

        position: absolute;
        top: 10px;
        right: 10px;

    }

    .card {
        margin-bottom: 0;
        background-color: $gray-100;
        border: none;
        border-radius: 6px;
    }

}
