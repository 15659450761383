.simple-nav {
    padding-left: 0;
    list-style: none;

    li {
        display: inline-block;
        border-bottom: solid 1px $gray-600;
        padding: 5px 10px;
        cursor: pointer;

        &:hover {
            background: $gray-300 !important;
        }

        &.active {

            border-top: solid 3px $primary;
            border-right: solid 1px $primary;
            border-left: solid 1px $primary;
            border-bottom: none;
        }

    }

}