.archk-recent-activity {

    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: rgba(0,0,0,.8);
    z-index: 1049;

    .close {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: 1;
        cursor: default !important;
    }

    .content {
        z-index: 2;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 500px;
        max-width: 100vw;
        box-shadow: 0 24px 38px 3px rgba(0,0,0,0.14), 0 9px 46px 8px rgba(0,0,0,0.12), 0 11px 15px -7px rgba(0,0,0,0.2);
        overflow: auto;

        .archk-recent-activity-heading {
            padding: $card-spacer-y $card-spacer-x;
            border-bottom: solid 1px $gray-200;
            font-weight: bold;
            font-size: 24px;
        }

        ul {
            margin-bottom: 0;
            padding-left: 0;

            li {
                list-style: none;
                padding: $card-spacer-y $card-spacer-x;
                border-bottom: solid 1px $gray-600;

                &:nth-child(odd) {
                    background: white;
                }

            }

        }

    }

}

@media(max-width: 815px) {

    .archk-recent-activity {
        top: 53px;
        width: 100vw;

        .content {
            width: 100%;
        }
    }

}

@media(max-width: 767px) {

    .archk-recent-activity {
        top: 60px;
        width: 100vw;

        .content {
            width: 100%;
        }
    }

}

#modal-invoice {
    z-index: 1100;
    .sweet-alert  {
        box-shadow: 0 24px 38px 3px rgba(0,0,0,0.14), 0 9px 46px 8px rgba(0,0,0,0.12), 0 11px 15px -7px rgba(0,0,0,0.2);
    }

}
