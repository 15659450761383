.archk-email-parser {

    $sidebarWidth: 250px;
    $headerHeight: 53px;
    
    .archk-email-parser-sidebar {
        width: 250px;
        position: fixed;
        left: 0;
        left: 50px;
        top: $headerHeight;
        bottom: 0;
        width: $sidebarWidth;
        background-color: $white;
        // padding-left: $card-spacer-x;
        // padding-right: $card-spacer-x;
        z-index: 1000;
        overflow: auto;

        // border-right: solid 1px $gray-300;

        .card {
            border: none;
            box-shadow: none;
            border-bottom: none;
        }

    }

    .archk-email-parser-main {
       margin-left: 250px;
    }



}