.archk-notifications {

    position: fixed;
    bottom: 0px;
    right: 10px;
    z-index: 99999999;
    background: rgba(255,255,255,.5);

    .notification {

        transition: all .5s;
        position: relative;
        background: $primary;
        box-shadow: 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.3);
        margin-bottom: 10px;
        max-width: 250px;
        border: none;

        .close {
            position: absolute;
            top: 0;
            right: 0;
            color: $white;
            cursor: pointer;
            font-size: 16px;
            padding: 14px 10px 9px;

            &:hover {
                background: lighten($primary, 10%);
            }

        }

        .card-header, .card-body, .card-footer {
            padding: 8px 16px;
        }

        .card-header {
            border-bottom: solid 1px rgba(255,255,255,.4);

            .card-title {
                max-width: calc(100% - 20px);
            }

        }

        .card-body {
            padding-bottom: 0;

            .text-muted {
                color: #cacdd1 !important;
            }
        }

        .title {
            color: $white;
        }



    }

}
