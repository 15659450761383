.archk-transfer-notifications {

    position: fixed;
    top: $spacer * 2;
    right: $spacer;
    z-index: 1100;

    .card {
        margin-bottom: 0;
        width: 250px;

        .card-body {
            padding: 8px 12px;
        } 

    }
    
}