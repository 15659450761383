
.navbar:not(.navbar-nav-hover) .dropdown-menu.show {

    background: white;
    border-radius: 0;

}

.dropdown-menu-dark {
    .h1, .h2, .h3, .h4, .h5, .h6, a {
        color: $body-color;
    }
}

.select2-results__option {
    background: $primary;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
    color: $white;
}

.select2-container--default li:hover {
    color: $primary !important;
}

.nav-dropdown.dropdown.show.nav-item .card-navigation.dropdown-menu-lg.dropdown-menu-dark.nav-master-menu.dropdown-menu.dropdown-menu-right.show {
    min-width: 0 !important;
    width: 300px;
    padding: 0;
    position: fixed !important;
    top: 10px !important;
    right: 16px !important;
    left: unset !important;
    transform: unset !important;

    .text-muted {
        color: #c3c3c3 !important;
    }

    .close {
        color: $white;
        top: 12px !important;
        right: 12px !important;
        position: absolute;
        font-size: 20px;
    }

    li a, li span {
        padding: 12px 10px;
        border-bottom: solid 1px #eee;
        display: block;
    }

    ul {
        margin-top: 0;
        margin-bottom: 0;
    }

    li a:hover, li span:hover {
        background-color: $gray-200;
    }

    li {

        .badge {
            border-radius: 100%;
            position: relative;
            top: -7px;
            width: 40px;
            height: 38px;
            display: inline-block;
            font-size: 12px;
            text-align: center;


            &.badge-warning {
                border: solid 1px lighten($orange, 20%);
            }

            &.badge-danger {
                border: solid 1px lighten($red, 30%);
            }

            &.badge-success {
                border: solid 1px lighten($green, 20%);
            }

        }

        font-weight: bold;

        i {
            display: inline-block;
            width: 20px;
            color: $green;
            font-weight: bold;
        }

    }

}
