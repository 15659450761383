.archk-image-renderer {

    z-index: 50;

    &:hover {

        .pop-out {
            display: block;
        }

    }

    .image-background {
        height: 210px;
        background-size: cover;
        background-position: top;
    }

    .pop-out {

        display: none;

        max-width: 300px;
        position: absolute;
        bottom: 15px;
        left: 30px;

        background: white;
        border-radius: 6px;
        border-bottom-left-radius: 0;
        overflow: hidden;

        .preview {
            width: 100%;
            height: 210px;
            position: relative;
            overflow: hidden;

            .no-preview-available {

                position: absolute;
                top: calc(50% - 10px);
                width: 100%;
                left: 0;
                right: 0;
                text-align: center;
            }
        }

        img, canvas{
            border-top-left-radius: 6px;
            border-top-right-radius: 6px;
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
        }

        img {
            height: 100%;
        }

        p {
            position: absolute;
            bottom: 28px;
            border-top: solid 1px #ddd;
            left: 0;
            right: 0;
            z-index: 999;
            margin-bottom: 0;
            font-size: 12px;
            color: $orange;
            text-align: center;
            font-weight: bold;

        }


        .pop-out-toolbar {

            ul {

                margin-left: 0;
                padding-left: 0;
                margin-bottom: 0;

                li.download-started {
                    width: 230px;
                }

                li {
                    display: inline-block;
                    padding: 3px 8px;
                    width: 115px;
                    text-align: center;
                    border-top: solid 1px #ddd;
                    border-right: solid 1px #ddd;

                    &:last-child {
                        border-right: none;
                    }

                    &:hover {
                        color: black;;
                        background: $gray-200;
                        cursor: pointer;
                    }

                }

            }

        }

    }

}