.cl-cntr, .archk-steps-to-prep {

    .archk-contact-questionnaires {

        padding-bottom: 20px;
        .navigation {
            display: none;
        }

        .row {
            border-bottom: none;
        }

        .change-alert {
            display: none;
        }

    }

}

.archk-contact-questionnaires {

    .navigation {

        width: 250px;

        ul {
            margin-bottom: 0;
            padding-left: 0;
            margin-left: 0;

            li {
                list-style: none;
                padding: 8px;
                cursor: pointer;
                border-bottom: solid 1px #eee;

                &.active {
                    background: $green;
                    color: $white;
                }
            }

        }

    }

    .branch {

        .important {
            border: solid 2px $orange;
            padding-left: $spacer;
            padding-right: $spacer;
            padding-top: $spacer;
            border-radius: $border-radius;
            margin-bottom: $spacer;
        }

        .branch {
            padding-left: 40px;
        }

    }

}