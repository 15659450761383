.billing-center {

    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: rgba(0,0,0,.8);
    z-index: 1049;

    .close {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: 1;
        cursor: default !important;
    }

    .content {
        z-index: 2;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 90vw;
        max-width: 90vw;
        background: $body-bg;
        box-shadow: 0 24px 38px 3px rgba(0,0,0,0.14), 0 9px 46px 8px rgba(0,0,0,0.12), 0 11px 15px -7px rgba(0,0,0,0.2);
        padding: $card-spacer-y $card-spacer-x;
        overflow: auto;
    }

}

@media(max-width: 815px) {

    .billing-center {
        top: 53px;
        width: 100vw;

        .content {
            width: 100%;
        }
    }

}

@media(max-width: 767px) {

    .billing-center {
        top: 60px;
        width: 100vw;

        .content {
            width: 100%;
        }
    }

}

#modal-invoice {
    z-index: 1100;
    .sweet-alert  {
        box-shadow: 0 24px 38px 3px rgba(0,0,0,0.14), 0 9px 46px 8px rgba(0,0,0,0.12), 0 11px 15px -7px rgba(0,0,0,0.2);
    }

}
