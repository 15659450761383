$border-color: #ddd;
$circle-color: white;

.full-background {
    .case_feed {
        height: auto !important;
    }

}
.case_feed {

    height: 812px ;

    .ql-editor {

        min-height: 47px;
        margin-bottom: 11px;
        margin-top: 1px;

        p {
            font-size: $font-size-sm;
        }

        a {
            text-decoration: underline;
        }

    }

    .card-header {

        button {
            width: auto !important;

            &.btn-block {
                width: 100% !important;
            }

        }

    }
    .form-control {
        background: white;
    }
    .feed-wrapper {

        position: relative;
        // margin-top: $spacer;

        .outside-email-wrapper {
            max-width: 325px !important;
            overflow: auto;
        }

        body {
            min-height: 0;

            table {
                width: 200px !important;
                max-width: 200px !important;
            }

            td {
                &:before {
                    display: none;
                }
                &:after {
                    display: none;
                }

                padding-left: 0 !important;

            }

        }

        td {
            padding-left: 30px;
            border-top: none;

        }

        &.feed-wrapper-alerts {

            td {
                padding-left: $spacer;
                border-top: none;
                &:before {
                    content: none;
                }
                &::after {
                    content: none;
                }

            }

        }

        .incoming-text {
            .item-wrapper {
                margin-right: 20px;
                background: linear-gradient(87deg,$gray-300,$gray-200)!important;

            }
        }

        .outgoing-text {
            .item-wrapper {
                background: linear-gradient(87deg,#2cc4e4,#3d8afc)!important;
                margin-left: 20px;

                h6 {
                    color: white !important;
                }

                .text-sm {
                    color: $gray-200
                }
            }
        }

        .incoming-email {
            .item-wrapper {
                margin-right: 20px;
                background: linear-gradient(87deg,$gray-300,$gray-200)!important;
            }
            .text-sm {
                color: $black
            }
        }

        .outgoing-email {
            .item-wrapper {
                background: $secondary !important;
                margin-left: 20px;

                .link {
                    &:hover {
                        color: $green;
                        cursor: pointer;
                    }
                }

                // h6 {
                //     color: white !important;
                // }

                // .text-sm {
                //     color: $gray-200
                // }
            }
        }

        .item-wrapper {
            background: $body-bg;
            padding: $spacer;
            border-radius: $border-radius;

           
        }

        .item-footer {
            border-bottom-left-radius: $border-radius;
            border-bottom-right-radius: $border-radius;
        }

        td {

            position: relative;
            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 1rem;
                height: 100%;
                border-right: $timeline-axis-width solid $timeline-axis-color;
            }

            &::after {
                content: '';
                position: absolute;
                width: 10px;
                height: 10px;
                background: $tealOriginal; //circle center
                top: calc(5px);
                left: 11px;
                border: 1px solid $border-color; //circle border
                border-radius: 100%;
            }

        }
        tr:first-child {

            td::before {
                top: 5px;
            }
        }
        tr:last-child {

            td::before {
                height: 5px;
            }
        }

    }

    .navigation {

        h4 {
            cursor: pointer;
        }

        .active {
            border-bottom: solid 3px $green;
            display: inline;
        }

    }

}
