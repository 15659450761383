.merge {



    .merge-selector {
        overflow: auto;
        width: 100%;
        overflow: auto;

        position: fixed;
        top: 89px;
        left: 250px;
        bottom: 0;
        width: 200px;

        .merge-card {
            cursor: default;
        
            .merge-preview {
                height: 150px;
            }

            // .merge-preview {
            //     height: 110px;
            // }

            // @media(max-height: 600px) {
            //     .merge-preview {
            //         height: 80px;
            //     }
            // }
        }
    }

    .merge-dnd-container {

        height: calc(100vh - 135px);
        overflow: auto;
        position: relative;
    }

    @media(max-height: 600px) {
        .merge-dnd-container {
            // height: calc(100vh - 290px);
        }
    }

    .merge-pack-status {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 25px;
        background-color: rgba(0,0,0,.5);
        text-align: center;
        color: white;
        padding-top: 100px;
        font-weight: bold;
        text-transform: uppercase;
        cursor: pointer;
    }

    .merge-card {
        position: relative;
        border: 3px solid;
        background-color: white;
        cursor: move;
        display: inline-block;
        width: 160px;
        margin-right: 10px;
        margin-bottom: 10px;

        .merge-header {
            padding: .25rem .5rem;
            border-bottom: solid 1px #bbb;

            &.merge-header-condensed {
                background: #111    
            }

        }

        &.merge-card-selected {
            position: relative;
            top: -6px;
            transform: rotate(1deg);
            transition: all .1s;
            border: solid 3px #ffd600 !important;
            -webkit-box-shadow: 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.3);
            box-shadow: 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.3);
        }

        &:hover {
            .merge-pack-status {
                display: block;
                z-index: 100;
            }
        
        }

        .merge-footer {
            padding: .25rem .5rem;
            border-top: solid 1px #bbb;
    
            .text-sm {
                font-size: 12px !important;
            }
        }
        
        .merge-preview {
            height: 130px;
            overflow: hidden;
            position: relative;

            .merge-tint {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                z-index: 2;
                opacity: .3;
            }

        }
    
        .merge-delete-icon, .merge-restore-icon {
            position: absolute;
            top: 3px;
            right: 3px;
            cursor: pointer;
            border: solid 1px red;
            border-radius: 100%;
            font-size: 10px;
            padding: 6px;
        }   

        .merge-expand-icon {
            position: absolute;
            top: 3px;
            left: 3px;
            cursor: pointer;
            border: solid 1px green;
            border-radius: 100%;
            font-size: 10px;
            padding: 6px;
        }

        .merge-rotate-left-icon {
            position: absolute;
            top: calc(50% - 0px);
            left: 5px;
            cursor: pointer;
            border-radius: 100%;
            color: black;
            background-color: white;
            font-size: 24px;
            cursor: pointer;
            z-index: 10;
        }

        .merge-rotate-right-icon {
            position: absolute;
            top: calc(50% - 0px);
            right: 5px;
            cursor: pointer;
            border-radius: 100%;
            color: black;
            background-color: white;
            font-size: 24px;
            cursor: pointer;
            z-index: 10;
        }

        .merge-restore-icon {
            border: solid 1px green;
        }

    }
    
    .merge-sidebar {
        width: 250px;
        border-right: solid 1px #ced4da;
        left: 0;
        position: fixed;
        top: 89px;
        bottom: 0;
        background-color: white;
        overflow: auto;
        z-index: 2;

        .merge-card {
            width: 100%;
            cursor: default;
            // margin-bottom: 0;
            width: 95px;

            .merge-preview {
                height: 95px;
            }
        }

        .section {
            padding: .6rem 1rem;
            border-bottom: solid 1px #ced4da
        }

        ul {

            padding-left: 0;
            margin-left: 0;
            margin-bottom: 0;

            li {
                list-style: none;
                padding: .6rem 1rem;
                border-bottom: solid 1px #ced4da
            }

       }

 
    }

    .merge-main {
        margin-left: 450px;
    }

    .merge-lighthouse-preview {

        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        z-index: 1100;
        background: rgba(0,0,0,.85);

        .merge-preview-counter {
            position: fixed;
            top: 27px;
            right: 75px;
            color: #ddd;
        }


        .loading-container {
            position: absolute;
            left: 0;
            right: 0;
            width: 100%;
            top: 48%;
        }

        .merge-preview-text {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            background: #181818;
            border-top: solid 1px rgba(255,255,255,.4);
            color: white;
            margin-bottom: 0;
            padding: 6px 20px;
            font-size: 26px;
            text-align: center;
        }

        .merge-lighthouse-preview-content {
            max-width: 1000px;
            height: 100%;
            margin: auto;
            text-emphasis: center;
            margin-top: -30px;
            max-width: calc(100% - 200px) !important;

            .rotate-button {
                position: fixed;
                top: 20px;
                left: 20px;
                width: 105px;
            }

            
            .rotate-right {
                left: 131px;
            }

            .flip {
                width: 220px;
                top: 60px;
                left: 16px
            }

            canvas, img {
                border: inset 5px #aaa !important;
                max-height: 80vh;
            }

            .row {
                height: 100%;
                text-align: center;
            }

            .caret {
                position: absolute;
                font-size: 40px;
                color: white;
                top: calc(50% - 20px);
                cursor: pointer;
                border: solid 2px white;
                border-radius: 100%;
                font-weight: bold;
            }

            .close {
                position: fixed !important;
                font-size: 40px;
                top: 20px;
                right: 20px;
                color: white;
                z-index: 1150;
                opacity: 1;
            }

            .move-left {
                left: -75px;
                padding-top: 2px;
                padding-bottom: 2px;
                padding-left: 12px;
                padding-right: 17px;
            }

            .move-right {
                right: -75px;
                padding-top: 2px;
                padding-bottom: 2px;
                padding-left: 17px;
                padding-right: 12px;
            }

        }

    }

    @media(max-width: 1300px) {

        .merge-card {
            width: 160px;

            // .merge-preview {
            //     height: 160px;
            // }
    
        }

    }
    
}