$width: 40px;

.step-tracker {

    width: 100%;
    p {
        font-size: $font-size-sm;
        margin-top: $width / 4;
        margin-bottom: 0;
    }

    .tracker {

        list-style: none;
        padding-left: 0;
        text-align: center;
        margin-bottom: 0;

        //calculate the width based on number of children
        @for $i from 1 through 10 {
            li:first-child:nth-last-child(#{$i}),
            li:first-child:nth-last-child(#{$i}) ~ li {

                width: 100% / $i;
            }
        }

        li {

            position: relative;
            display: inline-block;
            transition: all 2s;

            .connection, .finished-connection {
                width: 45%;
                display: inline-block;
                position: absolute;
                top: $width / 2;
                background: $gray-200;;
                height: 5px;
                right: -$width / 2;
                border-radius: $width;
            }

            .connection {
                z-index: 2;
            }

            .finished-connection {
                left: -$width / 2;
            }

            &:last-child {

                .connection {
                    display: none;
                }

            }
            &:first-child {

                // .connection {
                //     background: $green;
                // }

                .finished-connection {
                    display: none !important;
                }

            }

            &.current {
                .wrapper {
                    border: solid 2px $teal;
                    color: solid 2px $teal;
                }
            }

            .wrapper {

                display: inline-block;
                background: white;;

                height: $width;
                width: $width;

                border: solid 2px $gray-200;
                border-radius: 100%;
                text-align: center;
                font-size: $width / 2;
                padding-top: $width / 8;


                .done {
                    display: none;
                }

            }

            &.finished {

                .connection, .finished-connection {
                    background: $green;;

                }



                .wrapper {

                    background: $green;
                    border-color: $green;
                    color: $white;

                    .done {
                        display: inline-block;
                    }

                    .not-done {
                        display: none;
                    }

                }

            }

        }

    }

}
