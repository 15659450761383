
.call-center {

    $myTaskHeight: calc(50vh - 90px);
    $allTaskHeight: calc(50vh - 90px);

    $userTasksHeight: calc(100vh - 140px);

    padding-top: 5px;

    .container-fluid {
        max-width: 100%;
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .status {
        width: 6px;
        height: 6px;
        border-radius: 100%;
        display: inline-block;
        position: absolute;
        right: 0;
        top: calc(50% - 3px);

        &.active {
            background: $green;
        }

        &.online {
            background: $orange;
        }

        &.offline {
            background: $red;
        }
    }

    .cc-wrapper {
        padding-top: 10px;
        margin-top: -10px;
        overflow-x: auto;
        white-space: nowrap;

        .col-left {
            padding-right: 0;
        }

        .col-right {
            padding-left: 0;
        }

    }

    .cc-card {
        box-shadow: none;
        width: 250px;
        display: inline-block;
        color: white;
        vertical-align: top;

        &.cc-card-queue {
            width: 250px;
            color: inherit;

            li {
                border-bottom: solid 3px $gray-400;
                &:first-child {
                    border-top: solid 3px $gray-400;
                }
            }

        }

        &.user {

            li {
                background: lighten($primary, 0%);

                &.cc-in-progress {
                    background: $tealOriginal;
                    font-weight: bold;
                }

                &.cc-no-progress {
                    background: $orange;
                    font-weight: bold;
                }
            }

            .cc-tasks.all {
                height: $userTasksHeight;
                overflow: auto;
            }

        }

    }

    .card-header {
        border-bottom: solid 1px white;
    }

    .cc-card-queue {}
    .cc-tasks {

        padding-left: 0;
        margin-bottom: 0;

        &.user {
            li {
                background: #039de7;
            }
        }

        li {
            padding: $spacer * 0.75 $card-spacer-x;
            list-style: none;
            border-bottom: solid 1px $gray-200;

            overflow: hidden;
            // white-space: nowrap;
            white-space: pre-wrap;
            position: relative;
            max-width: 100%;

            transition: all 0.2s;
            &:hover {
                cursor: pointer;
                background-color: $green !important;
                border-left: solid 3px $green;
                color: white;

                .text-success {
                    color: $white !important;
                }

                .text-info-original {
                    color: $white !important;
                }

            }

        }

        &.urgent {
            max-height: $myTaskHeight;
            overflow: auto;
        }

        &.all {
            height: $allTaskHeight;
            overflow: auto;
        }

    }

}

.cc-modal-appointments {
    width: 1400px;
    max-width: 95%;

}

.cl-cntr {

    $minHeight: calc(100vh);
    $sidebarWidth: 200px;
    $mainWidth: calc(100% - 200px);
    $mainHeight: calc(100vh - 190px);

    $spacer: 10px;

    .browser-consent-banner {

        position: fixed;
        bottom: 0;
        right: 0;
        left: 0;

        background: white;
        z-index: 999;
        box-shadow: 0 24px 38px 3px rgba(0,0,0,0.14), 0 9px 46px 8px rgba(0,0,0,0.12), 0 11px 15px -7px rgba(0,0,0,0.2);
        border-top: solid 1px $gray-400;

        .container-fluid {
            padding-left: 15px !important;
            padding-right: 15px !important;
        }

        .row {
            border-bottom: 0;
        }

    }

    .archk-calendar {
        padding-left: $spacer;
        padding-right: $spacer;
        padding-bottom: $spacer;
    }

    .row {
        border-bottom: solid 1px $gray-300;
    }

    .contact-wrapper {
        .row {
            border-bottom: none;
        }
    }

    .content {
        padding-left: $spacer;
        padding-right: $spacer;
    }

    .container-fluid {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .col-left {
        background: $white;
        max-width: $sidebarWidth;
        width: $sidebarWidth;
        padding-right: 0;
        padding-left: 0;
        border-right: solid 1px $gray-300;
        position: fixed;
        top: $fixed-header-height + 1;
        bottom: 41px;
        left: 0;
        overflow: auto;
        box-shadow: 0 8px 17px 2px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0,0,0,0.2);
        z-index: 10;
        overflow: visible;

        &.full {
            bottom: 0;
        }

        ul {

            margin-bottom: 0;

            li {
                line-height: 14px;
                padding: $spacer * .5 $spacer;
                border-bottom: solid 1px #c7c7c7;
                left: 0;
                transition: all .3s;
                width: 100%;

                &.hover-select:hover {
                    background: $gray-400;
                    cursor: pointer
                }

                .name {
                    line-height: 16px;
                }

                &.title {
                    background: $white;
                    font-weight: bold;
                    line-height: 36px;
                    font-size: 1rem;
                }

            }

        }

    }

    .col-right {
        background: $gray-200;
        height: $mainHeight;
        background: $white;
        min-width: $mainWidth;
        max-width: $mainWidth;
        width: $mainWidth;
        padding-left: 0;
        padding-right: 0;
        margin-left: $sidebarWidth;
    }

    .info-bar {

        border-bottom: solid 1px $gray-300;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;

        button {
            position: relative;
            top: 1px;
        }

        ul {

            margin-bottom: 0;
            padding-top: $spacer * .5;
            padding-bottom: $spacer * .5;

            &.bar-left {
                text-align: right;
                float: left;
            }

            &.bar-right {
                text-align: right;
                float: right;
            }

            li {

                $width: 35px;

                display: inline-block;
                padding-right: $spacer * .5;
                width: auto;
                line-height: $width;
                font-size: $font-size-base * .9;

                &.descriptor {

                    background: $green;
                    border-radius: 100%;
                    width: $width;
                    height: $width;
                    text-align: center;
                    margin-right: 0;
                    margin-top: 2px;

                    -webkit-box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
                    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
 
                    &:last-child {
                        margin-right: $spacer;
                    }

                    &.descriptor-danger {
                        color: white;
                        background: $red;
                    }

                    &.descriptor-warning {
                        color: white;
                        background: $orange;
                    }

                    .descriptor-content {
                        width: $width;
                        display: inline-block;
                        font-weight: normal;
                    };

                }

            }

        }

    }

    @media(max-width: 820px) {

        .col-left {
            top: $fixed-header-height + 21;
        }

        .col-right {

            .info-bar {
                ul {

                    float: left;
                    padding-bottom: 7.5px;

                    li {
                        font-size: .8rem;
                    }

                }

            }

        }

        .on-call-banner {

            ul {

                text-align: left;

                li {
                    padding-left: 5px;
                    padding-right: 5px;
                }

                li.action-icon.first {
                    margin-left: 5px;
                }

                li:first-child {
                    padding-left: 5px
                }

            }

        }

    }



    .not-on-call {

        height: 100%;
        padding-top: 60px;

        .btn {
            font-size: 2rem;
            padding-right: $spacer * 3 !important;
            padding-left: $spacer * 3 !important;
        }

        .flex {

            height: 100%;
            display: flex;

        }

    }


}

#end-call-modal, .ql-editor-border {

    .ql-editor {
        border-bottom: solid 1px #dee2e6 !important;
        padding: 6px 12px !important;
    }

}

@media(min-width: 1000px) {
    #end-call-modal {
        max-width: 800px !important;
    }
}

@media(max-width: 1000px) {
    #end-call-modal {

        max-width: 90% !important;

    }

}


.on-call-banner {

    $height: 40px;

    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;

    background: white;
    z-index: 999999999999999;
    box-shadow: 0 24px 38px 3px rgba(0,0,0,0.14), 0 9px 46px 8px rgba(0,0,0,0.12), 0 11px 15px -7px rgba(0,0,0,0.2);
    border-top: solid 1px $gray-400;

    .pulse {
        color: $green;
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
        transform: scale(1);
        animation: pulse 2s infinite;
        border-radius: 50%;
    }
    @keyframes pulse {
        0% {
            transform: scale(0.95);
            box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
        }

        70% {
            transform: scale(1);
            box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
        }

        100% {
            transform: scale(0.95);
            box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
        }
    }

    ul {
        margin-bottom: 0;
        line-height: $height;
        text-align: center;
        overflow: visible;

        &.disabled {

            li.action-icon {
                opacity: .3;

                &:hover {
                    color: inherit;
                    cursor: default;
                    background: inherit;
                }
            }

        }

        li {
            display: inline-block;
            padding-left: 15px;
            padding-right: 15px;
            font-weight: bold;
            transition: all 0.5s;
            left: 0;

            &.action-icon {
                padding-left: 15px;
                padding-right: 15px;
                cursor: pointer;

                &.active {
                    background-color: $gray-800;
                    color: $green;
                }

                &:hover {
                    background-color: $gray-700;
                    position: relative;
                    color: white;

                }

                &.first {
                    margin-left: 80px;
                    display: inline-block;
                }

            }

        }

    }

    .action-wrapper {
        &:hover {
            .actions {
                display: block;
            }
        }

    }

    .actions {

        display: none;
        position: absolute;
        right: 0;
        bottom: $height;
        z-index: 100;
        background-color: $gray-700;
        box-shadow: 0 8px 17px 2px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0,0,0,0.2);

        ul {
            padding-left: 0;

            li {
                display: inline-block;
                text-align: left;
                padding-left: $spacer;
                padding-right: $spacer;
                width: 100%;
                color: white;
                &:hover {
                    left: 0;
                    background: $gray-600;
                }

                i {
                    display: inline-block;
                    width: 30px;
                }
            }
        }

    }

}

.keypad {

    position: fixed;
    text-align: center;

    width: 100%;
    left: 0;
    right: 0;
    top: 50px;

    z-index: 2000;

    .keypad-background {

        background: rgba(0,0,0,.5);
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        z-index: 1;

    }

    .keypad-content {

        width: 300px;
        background: white;
        box-shadow: 0 24px 38px 3px rgba(0,0,0,0.14), 0 9px 46px 8px rgba(0,0,0,0.12), 0 11px 15px -7px rgba(0,0,0,0.2);
        margin: auto;
        position: relative;
        z-index: 2;

        .backspace {

            i {
                position: relative;
                top: 8px;
            }

        }

        .btn {
            font-size: 1.5rem;
            padding-top: 10px;
            padding-bottom: 10px;
        }

        .header {
            font-size: 1.5rem;
            border-bottom: solid 1px $gray-400;
            padding: $spacer 0;
        }

        .block {
            font-size: 1.75rem;
            width: 33.3%;
            display: inline-block;
            border-bottom: solid 1px $gray-400;
            padding-top: 10px;

            transition: all 0.3s;
            &:hover {
                background-color: $gray-200;
                box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
                cursor: pointer;
            }

            &.last {
                border-bottom: 0;
            }

            &.first {
                border-right: solid 1px $gray-400;
            }

            &.second {
                border-right: solid 1px $gray-400;
            }

            .letters {
                font-size: $font-size-base;
                display: block;
                font-weight: normal;
                line-height: 10px;
                padding-bottom: 16px;
            }

            .placeholder {
                color: white;
            }

        }

    }

}


.call-center-mobile {

    .off-call-navigation {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;

        padding: 0;
        margin: 0;

        li {
            list-style: none;
            display: inline-block;
            width: 50%;
            text-align: center;
            padding: 8px;
            background: $gray-200;
            cursor: pointer;
            border-top: solid 1px $gray-300;

            i {
                margin-right: 5px;
            }

            &.active {
                background: $gray-800;
                color: white;
            }
        }

    }

    .dial-contact-from-search {
        position: fixed;
        bottom: 40px;
        left: $spacer;
        right: $spacer;
    }
    

    .keypad-mobile {


        text-align: center;

        width: 100%;
        left: 0;
        right: 0;
        top: 50px;

        z-index: 2000;

        .keypad-background {

            background: rgba(0,0,0,.5);
            position: fixed;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            z-index: 1;

        }

        .keypad-content {

            width: 100%;
            background: white;
            margin: auto;
            position: relative;
            z-index: 2;

            .backspace {

                i {
                    position: relative;
                    top: 8px;
                }

            }

            .btn {
                font-size: 1.5rem;
                padding-top: 10px;
                padding-bottom: 10px;
            }

            .header {
                font-size: 1.5rem;
                border-bottom: solid 1px $gray-400;
                padding: $spacer 0;
            }

            .block {
                font-size: 1.75rem;
                width: 33.3%;
                display: inline-block;
                border-bottom: solid 1px $gray-400;
                padding-top: 10px;

                transition: all 0.3s;
                &:hover {
                    background-color: $gray-200;
                    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
                    cursor: pointer;
                }

                &.last {
                    border-bottom: 0;
                }

                &.first {
                    border-right: solid 1px $gray-400;
                }

                &.second {
                    border-right: solid 1px $gray-400;
                }

                .letters {
                    font-size: $font-size-base;
                    display: block;
                    font-weight: normal;
                    line-height: 10px;
                    padding-bottom: 16px;
                }

                .placeholder {
                    color: white;
                }

            }

        }

    }

            
}


@media(max-width: 820px) {

    .on-call-banner {

        ul {

            text-align: left;

            li {
                padding-left: 5px;
                padding-right: 5px;
            }

            li.action-icon.first {
                margin-left: 5px;
            }

            li:first-child {
                padding-left: 5px
            }

        }

    }

}
