.list-hover {

    .card-body {
        padding: 0;
    }

    ul {

        padding-left: 0;
        list-style-type: none;

        li {
            padding: $card-spacer-y;
            transition: all .15s;
            border-left: solid 5px $primary;
            background: $gray-100;
            margin-bottom: 2px;
            margin-top: 0;
            left: 0;
            width: 100%;

            &:last-child {
                margin-bottom: 0;
            }

            &.active,&:hover {
                cursor: pointer;
                position: relative;
                width: calc(100% + 6px);
                border-left: solid 5px $green;
                background: white;
                box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
            }

            &:hover {
                position: relative;
                left: -6px;
                width: calc(100% + 12px);

            }

        }

    }

}
