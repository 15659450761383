.automations-layout {

    .header {
        box-shadow: 0 8px 17px 2px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0,0,0,0.2);
        margin-left: -15px;
        margin-right: -15px;
        padding-left: 15px;
        padding-right: 15px;
    }

    .auto-footer {
        position: fixed;
        bottom: 0;
        left: 250px;
        right: 0;
    }

    .auto-sidebar {

        .blackout {
            background: white;
        }

        &.open {
            .blackout {
                position: fixed;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                background: rgba(0,0,0,.5);
            }
        }

        .react-datepicker-popper {
            right: 20px !important;
            left: unset !important;
        }

        .wrapper {
            position: fixed;
            top: 0;
            bottom: 0;
            width: 225px;
            background: $white;
            z-index: 99999;
            box-shadow: 0 16px 24px 2px rgba(0,0,0,0.14), 0 6px 30px 5px rgba(0,0,0,0.12), 0 8px 10px -7px rgba(0,0,0,0.2);
            border-top: solid 1px $gray-400;
            right: -300px;
            transition: all 0.3s;

            &.open {
                right: 0;

                .blackout {
                    position: fixed;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 200px;
                    background: rgba(0,0,0,.5);
                }

            }

            ul {
                padding-left: 0;

                li {
                    list-style: none;
                    padding: $spacer;
                    border-bottom: solid 1px $gray-400;
                }
            }

        }

    }

    .auto-creator {

        padding-top: $spacer * 2;
        margin-left: 0;
        transition: all 0.3s;
        padding-bottom: 100px;

        &.sidebar-open {
            margin-left: -200px;
        }

        .auto {

            max-width: 500px;
            margin: auto auto $spacer;
            text-align: center;
            position: relative;

            &.selected {
                z-index: 99999999;
            }

            &.end, &.start {
                .wrapper {

                    border: none;
                    &:hover {
                        border: none;
                        cursor: default;
                        box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
                    }

                }
            }

            &.end, &.start, &.wait {

                $height: 47px;
                $margin: 160px;

                margin-bottom: $margin;
                margin-top: $margin;
                height: $height;
                &:first-child {

                    margin-top: 0;
                    &:before {
                        display: none;
                    }

                }

                &:last-child {

                    margin-bottom: 0;
                    &:after {
                        display: none;
                    }

                }

                &:before {
                    position: absolute;
                    border-left: 2px solid $gray-600;
                    width: 0;
                    height: $margin;
                    display: block;
                    content: '';
                    left: 50%;
                    z-index: 1;
                    bottom: $height;
                    margin-left: 0;
                    z-index: -1;
                }

                &:after {
                    position: absolute;
                    border-left: 2px solid $gray-600;
                    width: 0;
                    height: $margin;
                    display: block;
                    content: '';
                    left: 50%;
                    z-index: 1;
                    top: $height;
                    margin-left: 0;
                    z-index: -1;
                }

                .add {
                    position: absolute;
                    left: calc(50% - 20px);
                    padding: 8px 13px;
                    background: $white;
                    border-radius: 100%;
                    border: solid 1px $gray-400;
                    cursor: pointer;
                    transition: all 0.3s;
                    &:hover {
                        box-shadow: 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.3);
                        border: solid 1px $green;
                    }

                    &.before {
                        bottom: $height + ($margin / 2) - 25px;
                    }

                    &.after {
                        top: $height + ($margin / 2) - 20px;
                    }
                }

            }

            .wrapper {

                border-radius: 6px;
                text-align: left;
                margin: auto;
                max-width: 500px;
                display: inline-block;
                background-color: $white;
                padding: $spacer $spacer * 2;
                box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
                transition: all 0.3s;
                border: solid 1px $gray-100;

                &:hover {
                    cursor: pointer;
                    border: solid 1px $green;
                    box-shadow: 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.3);
                }

            }

            .title {

                font-size: 1.2rem;

                i.fa-envelope {
                    position: relative;
                    top: 1px;
                }

            }

            .description {
                font-size: 1rem;
            }

        }

    }

    .template-emails {

        .ql-editor {
            border-bottom: solid 1px #dee2e6 !important;

            p {
                font-size: $font-size-base !important;
                font-family: sans-serif;
                font-family: 'Open Sans' !important;
            }

        }

    }

}
