
.case-steps-container {
    padding: $spacer * 2 0;
}

.case-steps {

    overflow-x: scroll;
    box-shadow: 0 8px 17px 2px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0,0,0,0.2);
    display: inline-block;
    max-width: 100%;

    ul {
        padding-left: 0;
        overflow-x: auto;
        margin-bottom: 0;
        overflow: hidden;
        // border: solid 5px $white;
        box-shadow: $box-shadow;;
        position: relative;


        li {
            list-style: none;
            display: inline-block;
            position: relative;
            // width: 180px;

            &.active {
                background: $green;

                &:after {
                    border-left: 22px solid $green;
                }
            }

            &.main-step {
                background: $warning;
                color: $white;
                &:after {
                    border-left: 22px solid $warning;
                }
            }

            &:after{
                content:"";
                position:absolute;
                height:0;
                width:0;
                right: -42px;
                top:0;
                border:22px solid transparent;
                border-left: 22px solid $teal;
                z-index: 20;
            }

            &:before{
                content:"";
                position:absolute;
                height:0;
                width:0;
                right: -45px;
                top:0;
                border:22px solid transparent;
                border-left: 22px solid $white;
                z-index: 19;

                &:last-child {
                    border-left: none !important;
                }

            }

        }
    }

    .step {
        background: $teal;
        padding: 10px;
        padding-left: 40px;
        color: $white;

        &:first-child {
            padding-left: 10px;
        }

        &:last-child:after, &:last-child:before {
            border-left: none !important;
        }

    }

    .prompt {
        position: relative;
        top: 2px;
        font-weight: bold;
    }

}
