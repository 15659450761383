.page_workflows_view {
    .step-timeline {

        $border-color: #ddd;
        $circle-color: white;

        .timeline {
            margin-top: 20px;
            position: relative;
        }
        .timeline:before {
            position: absolute;
            content: '';
            width: 4px;
            height: calc(100% - 30px);
            background: $border-color; // line down
            left: 16px;
            top: 7px;
            border-radius: 4px;
        }

        .timeline-section {
            padding-left: 35px;
            display: block;
            position: relative;
            margin-bottom: 0;
        }

        .timeline-date {
            font-size: 0.9rem;
            margin-bottom: 15px;
            padding: 2px 15px;
            position: relative;
            display: inline-block;
            width: 100%;
            text-align: right;
            color: #fff;
        }
        .timeline-section:before {
            content: '';
            position: absolute;
            width: 30px;
            height: 3px;
            background-color: $border-color; //after right
            top: 12px;
            left: 20px;
        }
        .timeline-section:after {
            content: '';
            position: absolute;
            width: 10px;
            height: 10px;
            background: $circle-color; //circle center
            top: 7px;
            left: 11px;
            border: 1px solid $border-color; //circle border
            border-radius: 100%;
        }

        .timeline-section .col-sm-4 {
            margin-bottom: 15px;
        }

        &.bordered {

            $border-color: #ddd;
            $border-type: solid;

            $arrow-color: #555;
            $arrow-height: 15px solid;
            $arrow-top: -30px;
            $arrow-x: -24px;

            margin-left: 20px;
            margin-right: 20px;
            margin-bottom: 30px;

            .col-bordered {

                padding-top: 20px;
                padding-bottom: 20px;
                @mixin arrowTopGoingRight {

                    $odd: 'true';
                    @for $i from 1 through 100 {

                        //if is not multiple of four so 1,2,3, 5,6,7
                        //arrows going right or left
                        @if ceil($i / 4) != ($i / 4) {
                            //use $i for calculating mod here than #{$i}
                            @if ceil(($i - 1) / 4) != (($i - 1) / 4) {

                                //arrows going right
                                @if ($odd == 'true') {
                                    &:nth-child(#{$i}) {

                                        border-top: $border-type 3px $border-color;
                                        :after {
                                            z-index: 10;
                                            right: calc(50% - 10px);
                                            top: $arrow-top;
                                            border-top: $arrow-height transparent;
                                            border-left: $arrow-height transparent;
                                            border-top: $arrow-height $arrow-color;
                                            transform: rotate(45deg);
                                            position: absolute;
                                            width: 0;
                                            height: 0;
                                            clear: both;
                                            content: '';
                                        }
                                    }

                                    //arrows going left
                                }
                                @else {
                                    &:nth-child(#{$i}) {

                                        border-top: $border-type 3px $border-color;
                                        :after {
                                            z-index: 10;
                                            right: calc(50% - 10px);
                                            top: $arrow-top;
                                            border-bottom: $arrow-height transparent;
                                            border-right: $arrow-height transparent;
                                            border-bottom: $arrow-height $arrow-color;
                                            transform: rotate(45deg);
                                            position: absolute;
                                            width: 0;
                                            height: 0;
                                            clear: both;
                                            content: '';
                                        }
                                    }

                                }

                            }
                            @else {
                                 @if($i != 1){
                                    @if($odd == 'false') {
                                        &:nth-child(#{$i}) {

                                            border-top: $border-type 3px $border-color;
                                            border-left: $border-type 3px $border-color;
                                            border-bottom: $border-type 3px $border-color;
                                            border-top-left-radius: 10px;
                                            border-bottom-left-radius: 10px;
                                            :after {
                                                z-index: 10;
                                                left: $arrow-x;
                                                top: calc(50% - 10px);
                                                border-bottom: $arrow-height transparent;
                                                border-left: $arrow-height transparent;
                                                border-bottom: $arrow-height $arrow-color;
                                                transform: rotate(45deg);
                                                position: absolute;
                                                width: 0;
                                                height: 0;
                                                clear: both;
                                                content: '';
                                            }

                                        }
                                    }

                                }
                                @else {
                                    &:nth-child(#{$i}) {

                                        border-top: $border-type 3px $border-color;
                                        :after {
                                            z-index: 10;
                                            right: calc(50% - 10px);
                                            top: $arrow-top;
                                            border-top: $arrow-height transparent;
                                            border-left: $arrow-height transparent;
                                            border-top: $arrow-height $arrow-color;
                                            transform: rotate(45deg);
                                            position: absolute;
                                            width: 0;
                                            height: 0;
                                            clear: both;
                                            content: '';
                                        }
                                    }

                                }

                            }

                        }
                        @else {
                             @if($odd == 'true'){
                                &:nth-child(#{$i}) {

                                    border-top: $border-type 3px $border-color;
                                    border-bottom: $border-type 3px $border-color;
                                    border-right: $border-type 3px $border-color;
                                    border-bottom-right-radius: 10px;
                                    border-top-right-radius: 10px;
                                    :after {
                                        z-index: 10;
                                        right: $arrow-x;
                                        top: calc(50% - 10px);
                                        border-right: $arrow-height transparent;
                                        border-top: $arrow-height transparent;
                                        border-right: $arrow-height $arrow-color;
                                        transform: rotate(45deg);
                                        position: absolute;
                                        width: 0;
                                        height: 0;
                                        clear: both;
                                        content: '';
                                    }
                                }

                                $odd: 'false';
                            }
                            @else {

                                $odd: 'true';
                            }

                        }

                    }

                }

                @include arrowTopGoingRight;

                .card {
                    margin-bottom: 0;
                }

                a {
                    color: inherit;
                    &:after {
                        content: '';
                        border: none !important;
                    }
                }

                .number {
                    z-index: 10;
                    left: 0;
                    top: -20px;
                    position: absolute;
                    background: $green;
                    color: white;
                    border-radius: 100%;
                    width: 30px;
                    height: 30px;
                    padding-top: 2px;
                    text-align: center;
                    &:after {
                        content: '';
                        border: none !important;
                    }

                }

                i {
                    &:after {
                        content: '';
                        border: none !important;
                    }
                }

            }
        }
    }

    .automations {

        .form-group {
            margin-bottom: 0 !important;
        }

    }

    .archk-flow {

         .line {
            position: absolute;
            border-top: dotted 2px rgba(255,255,255,.3);
            z-index: 10;

        }

        .step-drawer {

            .backdrop {
                z-index: -1;
                background: rgba(0,0,0,.5);
                cursor: default;
            }

            .content {
                transition: all .7s;
                position: fixed;
                right: -1000px;
                top: 1000px;
                bottom: 0;
                height: 100%;
                width: 900px;
                max-width: calc(100% - 105px);
                background: black;
                z-index: 1050;
                overflow: auto;
                margin-top: 0;
            }

            &.show {

                .backdrop {
                    z-index: 1040;
                }

                .content {
                    right: 0;
                    top: 0;
                    background: white;
                }

            }

        }

        .sidebar {

            position: fixed;
            left: 0px;
            top: 89px;
            bottom: 0px;
            overflow: auto;
            background: $gray-200;

            width: 200px;

            .card {

                margin-bottom: 0;

                .card-body {
                    padding: 0;
                }

            }

            ul {
                padding-left: 0;
                margin-bottom: 0;

                li {
                    list-style: none;
                    border-bottom: solid 1px $gray-400;
                    padding: $spacer *.5 $card-spacer-x;
                    background: $gray-200;

                    i {
                        margin-right: 5px;
                    }
                    &:hover {
                        background: rgba(0,0,0,.8);
                        color: white;
                        cursor: pointer;
                    }

                    .step {
                        font-size: 1rem;
                    }

                    .description {
                        font-size: 0.8rem;
                    }

                }

            }

        }

        .flow {

            $border-color: $gray-600;
            $arrow-color: $gray-800;
            $border-color-hover: $green;

            margin-left: 185px;
            position: relative;
            margin-right: -15px;

            .drop-toggler {
                position: absolute;
                left: 5px;
                top: 5px;
                height: 20px;
                width: 20px;
                cursor: pointer;
                z-index: 3
            }

            .card {

                .card-header {
                    background: rgba(0,0,0,.8);
                    color: white;
                }

                .card-body {

                    background: rgba(0,0,0,.5);

                    ul {
                        li {
                            color: white;

                            &::after {
                                border-left: 6px solid rgba(255,255,255,.5);
                                border-top: 6px dotted transparent;
                                border-bottom: 6px dotted transparent;
                            }

                        }
                    }


                }

            }

            ul {

                margin-left: 20px;
                padding-left: 10px;
                margin-top: 10px;
                position: relative;
                width: auto;

                &::before {
                    content: '';
                    width: 46px;
                    border-left: dotted 1px $border-color;
                    position: absolute;
                    left: -3px;
                    height: calc(100% - 10px);
                    top: -15px;
                }
                &:hover::before {
                    border-left: solid 2px $border-color-hover;
                }

                &.ul-first::after,
                &.ul-first::before {
                    content: '';
                }

                &.ul-first:hover {

                    li {
                        color: $gray-200;
                    }

                }

                li {
                    position: relative;
                    list-style: none;
                    padding-left: 20px;
                    padding-bottom: 2px;
                    position: relative;
                    top: -11px;
                    width: auto;
                    color: $gray-700;

                    &.canCollapse {
                        height: 25px;
                        overflow: hidden;
                        background: #aaa;
                        color: black;

                    }

                    &.is-jump {
                        font-style: italic;
                        color: $gray-400 !important;
                        text-decoration: underline;

                        .fa-running {
                            color: $green !important;
                            text-shadow: 1px 1px 1px black;
                            border: solid 1px $orange;
                            padding-right: 3px;
                            padding-left: 3px;

                        }
                    }

                    &::before {
                        content: '';
                        width: 25px;
                        height: 0;
                        border-top: dotted 1px $border-color;
                        position: absolute;
                        left: -12px;
                        top: 11px;
                    }
                    &:hover::before {
                        border-top: solid 2px $border-color-hover;
                    }
                    &:after {
                        content: '';
                        position: absolute;
                        left: 12px;
                        top: 6px;
                        width: 0;
                        height: 0;
                        border-top: 5px dotted transparent;
                        border-bottom: 5px dotted transparent;
                        border-left: 5px solid $arrow-color;
                        clear: both;
                    }
                    &:hover::after {
                        border-left: 6px solid $border-color-hover !important;
                        border-top: 6px dotted transparent;
                        border-bottom: 6px dotted transparent;
                    }
                    &:hover {
                        font-weight: bold;
                        color: $white;
                        background: rgba(0,0,0,.2);
                        border: solid 1px rgba(255,255,255,.4);
                        border-right: none;
                        border-radius: 3px;
                    }

                }
            }

            .navigation {
                position: fixed;
                bottom: 15px;
                right: 30px;
                width: calc(100% - 215);
                background: white;
                border-radius: 6px;
                padding: 6px 12px;
                z-index: 10;
                border: inset 3px $green;
                background: lighten($green, 40%);
                color: darken($green, 20%)
            }

        }

    }

}
