.archk-no-scroll {
    overflow: hidden !important;
}


.archk-help-center {

    $white: $white;
    $green: $green;
    $gray: $gray-200;
    $spacer-y: $card-spacer-y;
    $spacer-x: $card-spacer-x;

    p {
        // font-size: .74375rem;
        margin-bottom:  $spacer-y;
        line-height: 1.7;
    }

    h3 {
        margin-top:  $spacer-y * 1.5;
        padding-top:  $spacer-y * 1.5;
        border-top: solid 1px $gray;

        &:first-child {
            margin-top: 0;
            padding-top: 0;
            border-top: none;

        }

    }

    ol, ul {

        padding-left: $spacer-x;

        li {
            // font-size: .74375rem;
            line-height: 1.7;
            font-weight: normal;
        }


    }

    table {

        td {
            border-top: solid 1px $gray;
            border-right: solid 1px $gray;
            border-bottom: solid 1px $gray;
            vertical-align: top;
            padding: $spacer-y $spacer-x;

            &:first-child {
                border-left: solid 1px $gray;;
            }

        }
        td,th {
                // font-size: .74375rem;
                line-height: 1.7;
                font-weight: normal;

        }
    }

    .backdrop {
        z-index: 1999;
        background: rgba(0,0,0,.5);
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        cursor: default;

    }

    .help-center {
        position: fixed;
        bottom: 20px;
        right: 20px;
        width: 440px;

        max-width: calc(100vw - 40px);
        max-height: calc(100vh - 40px);
        overflow: auto;
        box-shadow: 0 24px 38px 3px rgba(0,0,0,0.14), 0 9px 46px 8px rgba(0,0,0,0.12), 0 11px 15px -7px rgba(0,0,0,0.2);
        background: $white;
        z-index: 2000;
        margin: auto;

        .hc-header {
            padding: $spacer-y $spacer-x;
            position: relative;
            border-bottom: solid 1px $gray;

            .hc-header-title {
                margin-bottom: 0;
            }

            .hc-info {
                position: relative;
                top: 1px;
                color: $green;
            }

            .hc-close {
                position: absolute;
                top: $spacer-y;
                right: $spacer-x;
                padding: 6px 8px;
                border-radius: 100%;
                border: solid 1px $white;
                cursor: pointer;
                transition: all .5s;

                &:hover {
                    border: solid 1px $gray;
                    background-color: $gray;
                }
            }

        }

        .hc-body {
            padding: $spacer-y $spacer-x;
            border-bottom: solid 1px $gray;
        }

        .hc-footer {
            padding: $spacer-y $spacer-x;
        }


    }

    @media(max-width: 600px) {
        a.btn, button.btn {
            width: 100%;
            margin-left: 0;
            margin-right: 0;
            margin-bottom: 1rem;
        }
    }


}
