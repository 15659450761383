.archk-calendar {

    $titleFontSize: 1rem;
    $gray: $gray-400;
    $tdHeight: 39px;

    position: relative;
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;

    .title {
        font-size: $titleFontSize;
    }

    .archk-calendar-header {
        padding-bottom: $spacer;

        .timeframe-picker {
            border: solid 1px $gray;
            box-shadow: none;
            i {
                margin-left: $spacer;
            }
        }

        .month-year {
            font-size: $titleFontSize;
            font-weight: bold;
        }

        border-bottom: solid 1px $gray;

    }

    .sidebar {

        hr {
            width: 100%;
        }

        max-height: calc(100vh - 115px);
        overflow: auto;
        padding-bottom: 30px;

        width: 225px;
        padding-right: 1rem;
        display: -webkit-box;
        display: -webkit-flex;
        display: flex;
        -webkit-box-flex: none;
        -webkit-flex: none;
        flex: none;
        -webkit-flex-direction: column;
        flex-direction: column;

        position: relative;
        margin-top: 23px;

        .month-year {

            font-size: $titleFontSize;

            .title {
                float: left;
                padding-left: 8px;
                font-weight: normal;
            }

            .navigation {

                float: right;
                padding-right: 5px;


                i {

                    transition: all .5s;
                    cursor: pointer;
                    border-radius: 100%;
                    padding: 5px 9px;

                    &:hover {
                        background: $gray;
                    }

                }

                .back {
                    margin-right: .5rem;;;
                }

                .next {

                }

            }

        }

        .dates {

            .cell {

                text-align: center;
                padding: 6px;
                font-size: $titleFontSize * .8;;
                display: inline-block;
                width: calc(100% / 7);
                border-radius: 100%;
                transition: all .3s;

                &:hover {
                    background: $gray;
                    cursor: pointer;
                    border-radius: 50%;
                }

                &.header {
                    font-weight: bold;
                    &:hover {
                        background: none;
                        cursor: default;
                    }
                }

                &.other-month {
                    color: $gray;
                    &:hover {
                        background: none;
                        cursor: default;
                    }
                }

                &.today {
                    background: $tealOriginal;

                    color: white;
                }

                &.selected {
                    background: $green;
                    color: white;
                }

            }

        }

    }

    .table-responsive {
        max-height: calc(100vh - 177px);
        overflow: auto;
        border: solid 1px $gray;

        .table {
            margin-bottom: 0;
            border-collapse: separate;
            border-spacing: 0;
            tr {

                td:first-child {
                    border-left: none;
                }

                td:last-child {
                    border-right: none;
                }

                &:last-child {
                    td {
                        border-bottom: none;
                    }
                }

            }

            thead {
                th {
                    border-top: none;
                }
            }
        }

    }

    .calendar {

        margin-top: $spacer ;
        padding: 8px;
        flex: 1 1 auto;
        overflow: hidden;
        position: relative;
        max-height: calc(100vh - 95px);
        overflow: auto;

        .filter-button {
            min-width: 125px;
            margin-right: 0;
        }

        &.calendar-no-highlight {

            tbody {

                .cell {
                    border-bottom: none !important;
                }

                .indicator {
                    color: inherit !important;
                }

                .text-danger, .text-info-original, .text-success {
                    color: inherit !important;
                }

                .badge-danger, .badge-primary, .badge-success, .badge-warning {
                    background: $gray-200 !important;
                    color: $body-color !important;
                }

            }

        }

        thead {
            background-color: $table-thead-bg;

            padding-left: 0.5rem !important;
            padding-right: 0.5rem !important;

            th {

                text-align: center;
                border-bottom: solid 1px $gray !important;
                // border-top: solid 1px $gray !important;


                &:first-child {
                    border-right: solid 1px $gray !important;

                }
            }

        }

        tr.bg-gradient-danger td:hover,tr.bg-gradient-warning td:hover {
            background: $orange;
        }

        .view-week {

            tr {

                td {
                    padding: 2px;
                    overflow: auto;
                }

                .content-wrapper {
                    max-height: $tdHeight * 1.5;
                    height: $tdHeight * 1.5;
                }

                .index {
                    padding: 2px 5px;
                    margin-bottom: 2px;

                }
            }

        }

        .view-month {

            .month-content-wrapper {
                max-height: calc(100% - 20px);
                padding: 2px;
                overflow: auto;

                span {
                    margin-bottom: 2px;
                    margin-right: 0;
                }

            }

        }

        .view-day {

            tr {

                td:hover {
                    cursor: default;
                }

                .time {
                    position: sticky;
                    left: 0;
                    background-color: $white;
                    z-index: 9;
                    border-top: none;
                    font-size: 12px;
                }

                .content-wrapper {

                    max-height: $tdHeight;
                    height: $tdHeight;
                    padding-bottom: 10px;

                    .pop {
                        left: 0
                    }

                    span {
                        cursor: pointer;
                        z-index: 2;

                    }

                }

                .index {
                    width: 100px;
                    position: absolute;
                    white-space: normal;
                    top: 0;
                    font-size: 14px;;
                    border: solid 2px black;
                    line-height: 14px;
                    box-shadow: 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.3);
                }

            }

        }

        tr {

            .index {
                padding: 0 5px;
                color: white;
                border-radius: 4px;
                margin-right: 5px;
                display: inline-block;

                &:hover {
                    z-index: 3 !important;
                }
            }

        }

        td {

            height: 10px;
            padding-left: 0.5rem !important;
            padding-right: 0.5rem !important;
            border-right: solid 1px $gray;
            border-bottom: solid 1px $gray;
            border-top: solid 1px $gray;
            box-sizing: border-box;
            position: relative;
            padding: 0;

            transition: background 0.2s;
            cursor: pointer;

            &:first-child {
                border-left: solid 1px $gray;
            }

            &:hover {
                box-shadow: $box-shadow-sm;
                background: $gray-200;
            }

            &.day-of-other-month {
                cursor: inherit !important;

                &hover {
                    background: repeating-linear-gradient( 45deg, $gray-100, $gray-100 3px, $gray-200 3px, $gray-200 6px ) !important;
                }

                background: repeating-linear-gradient( 45deg, $gray-100, $gray-100 3px, $gray-200 3px, $gray-200 6px ) !important;
            }

            &.no-padding {
                padding-top: 0;
                padding-bottom: 0;
            }

            &.time {
                background: inherit;
            }

            &.cell {

                &.task-overdue, &.today {

                    border-bottom: solid 4px $green;

                    .day {
                        color: white;
                        background-color: $green;
                        border: solid 1px darken($green, 20%);
                        padding: 3px 9px;
                        font-weight: bold;
                        border-radius: 100%;
                    }
                }

                .day {
                    position: absolute;
                    top: 5px;
                    left: 5px;
                }

                .indicator {
                    position: absolute;
                    top: 4px;
                    right: 10px;

                    &.fa-circle {
                        font-size: 1.4rem !important;
                        left: 1px;
                        font-size: 1rem;
                        top: 4px;
                    }

                }

            }

        }

        .view-day {

            &.table {

                td:hover {
                    background: inherit
                }

            }

            .timeslot {
                font-size: $spacer;
            }

        }

        .view-month {

            i {
                font-size: 0.7rem !important;
            }

            .archk-calendar-active {
                background: $orange;
                background: linear-gradient(to bottom right,lighten($black, 10%), darken($black, 10%));
                color: white;

                i {
                    color: white;
                }

                .text-danger {
                    color: $white !important;
                }

                .text-green {
                    color: $white !important;
                }

                .day {
                    color: $white;
                }

                &.today {
                    .day {
                        color: $black;
                    }
                }

                &.task-overdue {
                    .indicator {
                        color: $orange;
                    }
                }
            }

            &.fullscreen {
                td {
                    height: calc(((100vh - 180px) / 5) );
                }
            }

            td {
                vertical-align: bottom;
                max-height: 100px;
                position: relative;
            }

        }

        .badge-div {

            margin-left: -$table-cell-padding * 2;
            margin-right: -$table-cell-padding * 2;
            padding-left: $table-cell-padding * 2;
            padding-right: $table-cell-padding * 2;

            &.no-badge {
                padding: 6.4px 0;
                margin-left: -$table-cell-padding * 2.5;
                margin-right: -$table-cell-padding * 2.5;
                padding-left: $table-cell-padding * 2.5;
                padding-right: $table-cell-padding * 2.5;
            }

            border-bottom: solid 1px $gray-200;
            &:last-child {
                border-bottom: none;

            }

        }
        @media(max-width: 768px) {

            .card-header {

                .dropdown, button {
                    width: 100%;
                    margin-top: $spacer;
                }

            }

        }
        @media(min-height: 900px) {

            .view-month {

                td {
                    height: 100px;
                }

                &.fullscreen {
                    td {
                        height: calc(((100vh - 180px) / 5) );
                    }
                }
            }
        }
        @media(min-width: 768px) {
            .full {
                display: inline-block;
            }

            .circle {
                display: none;
            }
        }
        @media(max-width: 768px) {

            .view-month {

                td {
                    height: 60px;

                    &.cell {
                        font-size: 5px;

                        .day {
                            font-size: 10px;
                        }

                        .full {
                            display: none;
                        }

                        .circle {
                            display: inline-block;
                        }

                    }
                }


                &.fullscreen {
                    td {
                        height: 60px;
                    }
                }
            }
        }



    }
}
