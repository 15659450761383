.table {

    td {
        vertical-align: middle;
        word-break: break-all;
        word-break: break-word;

    }

    .aligned-image {
        position: relative;
        margin-top: -0px;

    }

    &.table-hover {

        tr:hover {
            background: $white;
            background: $gray-300 !important;
            cursor: pointer;
            z-index: 1000;

            td {
                position: relative;
            }

        }

    }

    &.table-sticky-first-col {
        tr {
            th:first-child, td:first-child {
                position: sticky;
                left: 0;
                background: white;
                z-index: 2;
                // background: $gray-100;

                &::after {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    width: 1px;
                    background: red;
                    z-index: 3;
                    border-right: solid 1px $gray-200;
                    content: ''
                }
            }
        }
    }

}

.react-bs-table-csv-btn {
    background: $primary !important;
    border-color: $primary !important;
}

.page-item.active .page-link {
    z-index: 1;
    color: #fff;
    background-color: $teal;
    border-color: $teal;
}

.table-no-margin {
    table.table {
        margin-bottom: 0;
    }
}

.table-responsive {

    .row {
        margin-left: 0;
        margin-right: 0;
    }

    .col-md-6:first-child, .col-xs-6:first-child, .col-sm-6:first-child, .col-lg-6:first-child {
        padding-left: 0;;
    }

    .col-md-6:last-child, .col-xs-6:last-child, .col-sm-6:last-child, .col-lg-6:last-child {
        padding-right: 0;;
    }

}

.table-not-fixed {

    table.table {
        table-layout: unset;

        td {
            word-break: break-word;
            white-space: pre;
        }
    }

}

.react-bootstrap-table-pagination {

    position: sticky;
    left: 0;
    
    .dataTables_length select.form-control-sm {
        width: 40px;
        text-align: center;
        margin: auto;
    }

}

.table-parent-bordered {

    .table {

        td {
            border: solid 4px $gray-200;
        }

        .react-json-view {
            font-size: .8rem;
        }

    }

}

.archk-table-expansion {

    position: relative;

    .archk-table-expansion-content {
        display: none;
        position: absolute;
        top: -20px;
        background-color: $white;
        padding: $spacer;
        z-index: 999;
        box-shadow: 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.3);
        cursor: default;
    }

    .archk-table-expansion-teaser {
        display: inline;
    }

}

.table-notifications {

    a:not(.no-highlight) {
        color: $green;
        margin-right: 10px;
        font-weight: bold;
    }

}

.react-bootstrap-table {
    .table {
        margin-bottom: $spacer

    }
}

.pagination.react-bootstrap-table-page-btns-ul {

    margin-bottom: $spacer;
    padding-right: $spacer !important;

    li {

        a {
            width: 33px;
            height: 33px;
        }

    }

}

@media(max-width: 768px) {
    .react-bootstrap-table-pagination-total {
         display: none;
    }

    .dataTables_filter {
        input, select {
            width: 100%;
        }
    }

    .dataTables_length {
        display: none !important
    }

}

