.card {

    margin-bottom: $spacer * 2;
    border: solid 1px #e9e6e6;

    .card-title {
        font-weight: bold;
        position: relative;
        top: 2px;
    }

    &.card-color {

        $border: 5px;
        $height: 4px;

        &:before {
            content: '';
            top: 1px;
            right: $border;
            left: $border;
            position: absolute;
            height: $height;
            z-index: -1;
            margin: -$border;
            border-radius: inherit;
        }

        &.card-warning {
            &:before {
                background: linear-gradient(to right, orange);
            }
        }

        &.card-success {
            &:before {
                background: linear-gradient(to right, lighten($green, 10%), darken($green, 10%), lighten($green, 10%), darken($green, 10%));
            }
        }

        &.card-primary {
            &:before {
                background: linear-gradient(to right,darken( $tealOriginal, 0%));
            }
        }
        &.card-danger {
            &:before {
                background: linear-gradient(to right, $red);
            }
        }

        &.card-yellow {
            &:before {
                background: linear-gradient(to right, $yellow);
            }
        }

    }

}
