
.modal-unfinished-tasks {
    width: 1000px;
    max-width: 95%;

    .right-column {
        border-left: solid 1px $gray-200;
    }

}

.page_case_view {

    .tasks-view {
        button.btn {
            width: 150px;
        }
    }

    .case-header {
        top: $fixed-header-height;
        background: $gray-100;
        box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
        z-index: 100;
    }

    .tag-wrapper {
        overflow-x: auto;
        height: 45px;

        .tag-inner {
            max-width: 100%;
            display: inline-block;
            white-space: pre;
        }
    }

    .floating-nav {
        background: $primary;
        color: white;
        box-shadow: $box-shadow;
        font-size: 1rem;
        padding: 12px 15px;
        border-radius: 100%;;
        z-index: 2005;

        i {
            position: relative;
            top: 1px;
        }

    }

    .case-navigation {
        padding-bottom: 120px;
    }

    .main-case-content {
        margin-top: -130px;;
    }

    .card-header {
        button, a {
            width: 100px
        }

        .dropdown {
            button, a {
                width: 100%
            }
        }
    }

    a.document-link {
        color: $orange;
        text-decoration: underline;
    }

    .employees-table {
        height: 220px;
        overflow: auto;
    }

    .appointment-table {
        height: 188px;
        overflow: auto;
    }

    .parties-table {
        height: 220px;
        overflow: auto;
    }

    @media(max-width: 1102px) {

        #case_feed {

            .col-3 {
                padding-left: 3px;
                padding-right: 0;
            }
            .col-3:first-child {
                padding-left: 15px;
            }
            .col-3:last-child {
                padding-right: 15px;
            }

        }

    }


}

#case_feed {
    
    .card-title {
        cursor: pointer;
        &:hover {
            color: $green
        }
    }

}