
.hover-popup {

    position: relative;
    cursor: pointer;
    
    &:hover {

        .pop {
            display: inline-block;
            position: absolute;
            top: -$spacer * 4;
            right: $spacer * 2;
            background: $body-bg;
            border: solid 1px #ddd;
            border-radius: $border-radius;
            padding: $spacer;
            white-space: pre;
            word-break: break-all;
            box-shadow: $box-shadow-sm;
            z-index: 9999999999;
            width: 200px;
            white-space: inherit;
            word-break: break-word;
            box-shadow: 0 8px 17px 2px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0,0,0,0.2);
        }

    }

    .pop {
        display: none;
    }

}
