#page-questionnaire-edit {


    .col-flow {
        left: 50px;
        border-right: solid 1px $gray-200;
    }

    .col-selected {
        left: calc(33.3vw + 33.2px);
        border-right: solid 1px $gray-200;
    }

    .col-overview {
        left: calc(66.6vw + 16.6px);

    }


    .row {
        margin-left: 0;
        margin-right: 0;

        &.archk-row-normal {
            margin-left: -$spacer;
            margin-right: -$spacer;
        }
    }

    .col {
        padding-left: $card-spacer-x;
        padding-right: $card-spacer-x;
        padding-bottom: $card-spacer-y;
        padding-top: $card-spacer-y;
        position: fixed;
        top: 53px;
        bottom: 0;
        overflow: auto;
        max-width: calc(33.3% - 16.6px);

        .archk-col-header {
            padding: 8px 0;
            border-bottom: solid 1px $gray-400;
            margin-bottom: 10px;

            padding-left: $card-spacer-x / 2;
            padding-right: $card-spacer-x / 2;
            margin-left: -$card-spacer-x;
            margin-right: -$card-spacer-x;

            .btn {
                position: relative;
                top: -4px;
                margin-top: -2px;
            }
        }
        .archk-col-footer {
            padding: 11px 0;
            border-top: solid 1px $gray-400;
        }

    }

   

    .question-wrapper {
        border: solid 1px $gray-200;
        border-radius: 16px;
        padding: $spacer;

        &:hover {
            border: solid 1px $tealOriginal;
        }

        .btn {
            padding: $spacer * .5 $spacer !important;
            border-radius: 16px !important;
        }

        .question {
            border: 1px dashed $gray-600;
            padding: $spacer * .5 $spacer;
            border-radius: 16px;
            margin-bottom: .5rem;
            background: white;
            cursor: move;
            position: relative;

            &:hover {
                border: 1px solid $tealOriginal;
                background: lighten($tealOriginal, 35%) !important;
                
                .edit-icon {
                    border: solid 1px $white;
                    color: $white !important;
                }

            }

            &.question-drag {
                background: $green !important;
                opacity: .5 !important;
                color: $white !important;
            }

            &.archk-selected-question {
                background: $tealOriginal !important;
                color: $white !important;

                .edit-icon, .branch-icon {
                    border: solid 1px $white;
                    color: $white !important;

                    &:hover {
                        border: solid 1px $white;
                        background: $tealOriginal;
                        color: $white !important;
                        cursor: pointer;
                        transform: rotate(0);
                    }

                }

            }

          

            .edit-icon, .branch-icon {
                border: solid 1px $green;
                position: absolute;
                right: 5px;
                top: 5px;
                padding: 3px;
                border-radius: 100%;
                z-index: 2;
                transition: all .2s;
                transform: rotate(0deg);
    
                &:hover {
                    border: solid 1px $tealOriginal;
                    background: $tealOriginal;
                    color: $white !important;
                    cursor: pointer;
                    transform: rotate(-45deg);
                }
    
            }

            .branch-icon {
                border: solid 1px $orange;
                right: 35px !important;   
                padding-left: 6px; 
            }
    
           
        }

        .nested-level {

            margin-left: 20px;
    
            .question {
                background: $gray-100;
            }
    
        }
    
        .nested-level-3 {
    
            margin-left: 20px;
    
            .question {
                background: $gray-200;
            }
    
        }

    }

}